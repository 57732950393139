import { get, set } from 'solid-utils/access';
import { createEffect, createSignal, Show, useContext } from 'solid-js';
import { createAsync, useParams } from '@solidjs/router';

import { TaskViewModeGroupType, TaskViewModeType } from 'shared/network/schema';

import { fetchViewMode, postViewMode, updateViewMode } from '#/view-modes/network';
import { fetchViewModes } from '#/view-modes/network';
import { deleteViewMode } from '#/view-modes/network';
import { ViewMode } from '#/view-modes/model';

import { ClientUser } from '../users.adapter';
import { ProjectContext } from '../project.context';

import TextArea from 'ui/elements/text-area';
import { MainButton } from 'shared/ui/telegram';
import List from 'shared/ui/list';
import Checkbox, { CheckboxStyle } from 'shared/ui/checkbox';


export default function FiltersPage() {
  const [project] = useContext(ProjectContext);
  const params = useParams();
  const title = createSignal('');
  const query = createSignal('');
  const isPublic = createSignal(true);
  const id = createSignal('');
  let author = {};

  const [shouldRefresh, refresh] = createSignal(null, { equals: () => false });

  const projectViewModes = createAsync(() => (
    shouldRefresh(),
    fetchViewModes(project.latest.id)
  ), { initialValue: [] });

  createEffect(() => {
    if (params.filterId && params.filterId !== 'new') {
      set(id, params.filterId);

      fetchViewMode(params.filterId)
        .then(mode => {
          if (!mode) {
            throw 'What? no mode!';
          }

          author = mode.author;
          set(id, mode.id);
          set(query, mode.query);
          set(isPublic, mode.type === TaskViewModeType.Public);
          set(title, mode.title);
        });
    }
  });

  return <main class="p-4">
    <p>project id: {project.latest.id}</p>
    <hr />

    <p>
      id: {get(id)}
      <Show when={get(id)}>
        <span class="c-text-negative!" onClick={() => set(id, '')}> X</span>
      </Show>
    </p>
    <hr />
    <p>title:</p>
    <TextArea model={title} class="bg-primary rounded-2 p-2" />
    <hr />
    <p>query:</p>
    <TextArea model={query} class="bg-primary rounded-2 p-2" />
    <hr />
    <p>is public: <Checkbox iconStyle={CheckboxStyle.Telegram} class="inline-block" model={isPublic} /></p>

    <MainButton
      onClick={() => {
        if (!get(id))
          return postViewMode({
            author,
            query: query[0](),
            projectId: project.latest.id,
            title: title[0](),
            type: isPublic[0]() ? TaskViewModeType.Public : TaskViewModeType.Private,
            groupBy: TaskViewModeGroupType.Default,
          });

        updateViewMode(get(id), {
          id: get(id),
          author,
          query: query[0](),
          projectId: project.latest.id,
          title: title[0](),
          type: isPublic[0]() ? TaskViewModeType.Public : TaskViewModeType.Private,
          groupBy: TaskViewModeGroupType.Default,
        });
      }}
      text={get(id) ? 'Update' : 'Add'}
    />
  </main>;
}